var page_loaded = 1;
global.page_loaded = page_loaded;

// global.jQuery = require('jquery');
// var slickInit = require('slick-carousel');

var flexsliderLoad = require('flexslider'),
    flexslider = require('../modules/slider');

page = {
	load: function(){
		// this.newsCar();
		this.flexslider();
		this.tabs();
	},
	newsCar: function(){
		jQuery('.postList__gal').slick({
			slidesToShow: 1,
		  	slidesToScroll: 1,
		  	speed: 350,
		  	fade: false,
		  	arrows: true,
		  	dots: false
		});
	},
	tabs: function(){
		$('.sing__video').fadeOut(0);
		$('.sing__tab').on('click', function(){
			var el = $(this).data('tab');
			$('.sing__el').fadeOut(150);
			setTimeout(function(){
				$('[data-src="'+el+'"]').fadeIn();
			}, 150)
		})
	},
	flexslider: function(){
	    slider = $('.sing__gal');
	    slider.each(function() {
	      flexslider.gallery(slider);
	    });
	},
}


$(window).on('load', function(){
	page.load();
})